import "core-js/modules/es.object.keys.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [Object.keys(_vm.queryObj).length ? _c("a-card", {
    staticClass: "details",
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("span", {
    staticClass: "box"
  }, [_c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("订单编号：")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.filterRecordValue(_vm.queryObj.orderno)))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("状态：")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.statusTxt(_vm.queryObj.status)))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("鉴定结果：")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.filterRecordValue(_vm.queryObj.resulttxt)))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("类目：")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.filterRecordValue(_vm.queryObj.categoryname)))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("品牌：")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.filterRecordValue(_vm.queryObj.brandname)))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("鉴定类型：")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.typeTxt(_vm.queryObj.type)))])])])]) : _vm._e(), _c("a-card", {
    staticStyle: {
      "overflow-x": "scroll",
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return i;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "usertype" ? _c("span", [_c("span", [_vm._v(_vm._s(_vm.usertypeText(record[key])))])]) : key == "items" ? _c("span", [record.items && record.items.length ? _c("span", [_c("img", {
            staticClass: "previe_img",
            attrs: {
              src: _vm.coverImg(record.items)
            },
            on: {
              click: function click($event) {
                return _vm.preview(record.items);
              }
            }
          }), _c("span", {
            staticStyle: {
              color: "#3270F5",
              "margin-left": "10px",
              cursor: "pointer"
            },
            on: {
              click: function click($event) {
                return _vm.handleDetail(record.items);
              }
            }
          }, [_vm._v("查看")])]) : _c("span", [_vm._v(" --- ")])]) : key == "name" ? _c("span", [_c("span", [_vm._v(_vm._s(record.usertype == "1" || record.usertype == "0" ? record.username ? record.username : "---" : record.nickname ? record.nickname : "---"))])]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("div", {
    staticClass: "footer"
  }, [_c("a-button", {
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleBack();
      }
    }
  }, [_vm._v("返回")])], 1)], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  }), _c("DPopup", {
    attrs: {
      modalWidth: "529px",
      title: _vm.checkTitle,
      comVisible: _vm.checkVisible
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("Check", {
          attrs: {
            memo: _vm.memo,
            images: _vm.images
          },
          on: {
            handleAddSubmit: _vm.handleAddSubmitSuccess
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };