import "core-js/modules/es.number.constructor.js";
export default {
  props: {
    images: {
      type: Array
    },
    editId: {
      type: String | Number
    },
    memo: {
      type: String
    }
  },
  data: function data() {
    return {
      defaultIndex: 0,
      // 轮播图图片默认index
      pictureId: 0 // 鉴定图片ID
    };
  },

  computed: {},
  created: function created() {},
  methods: {
    onChange: function onChange(a, b, c) {
      this.defaultIndex = a;
    },
    btnClick: function btnClick() {
      this.$emit('handleAddSubmit');
    },
    handleClick: function handleClick(item, index) {
      // 跳转至指定位置
      this.defaultIndex = index;
      this.$refs['checkCarousel'].$refs.slick.innerSlider.slickGoTo(index);
    }
  }
};