import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import { mapState, mapActions } from "vuex";
import Check from './components/Check.vue';
import Mixin from '@/utils/mixin';
import { CheckerApi } from '@/api';
import { detailColumns } from './config';
export default {
  name: "orderDetail",
  components: {
    Check: Check
  },
  data: function data() {
    return {
      loading: false,
      queryObj: {},
      columns: detailColumns,
      dataSource: [],
      previewVisible: false,
      previewImage: '',
      checkTitle: '',
      checkVisible: false,
      images: [],
      memo: ''
    };
  },
  mixins: [Mixin],
  computed: {
    editId: function editId() {
      return this.queryObj.id;
    },
    usertypeText: function usertypeText() {
      return function (key) {
        switch (key) {
          case 0:
            return '系统';
          case 1:
            return 'app用户';
          case 2:
            return '鉴定师';
        }
      };
    },
    coverImg: function coverImg() {
      return function (val) {
        return val[0].imgurl;
      };
    }
  },
  created: function created() {
    var queryObj = this.$route.query.obj && JSON.parse(this.$route.query.obj);
    this.queryObj = queryObj || {};
    this.getOrderDetail();
  },
  methods: {
    handleDetail: function handleDetail(val) {
      this.checkTitle = "".concat(this.queryObj.brandname, " ").concat(this.queryObj.categoryname);
      this.images = val;
      this.memo = this.queryObj.memo || '';
      this.checkVisible = true;
    },
    handleAddSubmitSuccess: function handleAddSubmitSuccess() {
      this.checkVisible = false;
    },
    previewHandleCancel: function previewHandleCancel() {
      this.previewVisible = false;
    },
    preview: function preview(items) {
      this.previewImage = '';
      for (var i = 0; i < items.length; i++) {
        this.previewImage += items[i].imgurl + ',';
      }
      this.previewVisible = true;
    },
    getOrderDetail: function getOrderDetail() {
      var _this = this;
      this.loading = true;
      CheckerApi.getcheckloglist({
        id: this.editId
      }).then(function (res) {
        _this.loading = false;
        _this.dataSource = res;
      }).catch(function () {
        setTimeout(function () {
          _this.loading = false;
          _this.dataSource = [];
        }, 1500);
      });
    },
    handleBack: function handleBack() {
      this.$router.push('/check/index');
    }
  }
};