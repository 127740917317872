var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "model_box"
  }, [_vm.images && _vm.images.length > 0 ? _c("div", {
    staticClass: "top_opt_container"
  }, [_c("div", {
    staticClass: "flaw_pic_list"
  }, [_c("a-carousel", {
    ref: "checkCarousel",
    attrs: {
      "after-change": _vm.onChange,
      arrows: "",
      dots: false
    }
  }, [_c("div", {
    staticClass: "custom_slick_arrow",
    attrs: {
      slot: "prevArrow"
    },
    slot: "prevArrow"
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/img/c_left.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "custom_slick_arrow custom_slick_arrow_r",
    attrs: {
      slot: "nextArrow"
    },
    slot: "nextArrow"
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/img/c_right.png"),
      alt: ""
    }
  })]), _vm._l(_vm.images, function (item, index) {
    return _c("div", {
      key: item.id,
      staticClass: "swipers"
    }, [_c("div", {
      staticClass: "flaw_pic_title"
    }, [_c("img", {
      attrs: {
        src: item.imgurl,
        alt: ""
      }
    })])]);
  })], 2)], 1), _c("div", {
    staticClass: "img_show"
  }, _vm._l(_vm.images, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "img_box"
    }, [_c("span", {
      on: {
        click: function click($event) {
          return _vm.handleClick(item, index);
        }
      }
    }, [_c("img", {
      class: _vm.defaultIndex == index ? "picked" : "",
      attrs: {
        src: item.imgurl,
        alt: ""
      }
    })])]);
  }), 0)]) : _vm._e(), _c("div", {
    staticClass: "btn_box"
  }, [_vm.memo ? _c("div", {
    staticClass: "order_desc"
  }, [_c("span", [_vm._v("备注：" + _vm._s(_vm.memo))])]) : _vm._e()]), _c("div", {
    staticClass: "btn"
  }, [_c("a-button", {
    staticStyle: {
      border: "0",
      width: "108px"
    },
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.btnClick();
      }
    }
  }, [_vm._v(" 关闭 ")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };